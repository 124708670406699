import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TextArea } from 'hds-react';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/text-area",
  "title": "TextArea",
  "navTitle": "TextArea"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PlaygroundPreview = makeShortcode("PlaygroundPreview");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TextArea id="textarea" label="Label" placeholder="Placeholder" helperText="Assistive text" required style={{
        maxWidth: '320px'
      }} mdxType="TextArea" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with a text area.`}</strong></li>
      <li parentName="ul">{`Use a text area when multiples lines of text is expected. For single line inputs, use a `}<InternalLink href="/components/text-input" mdxType="InternalLink">{`TextInput component.`}</InternalLink></li>
      <li parentName="ul">{`Allow the text area to reflect the length of the content you expect the user to enter.`}</li>
      <li parentName="ul">{`If possible, add programmatic assistance. Detect and pre-fill inputs to reduce errors and save time. Use sentence-case for default values, detected values, and auto-completion text.`}</li>
      <li parentName="ul">{`If possible, do input validation in client-side real-time and provide the user with immediate feedback. See `}<InternalLink href="/patterns/forms/form-validation" mdxType="InternalLink">{`HDS Form validation pattern page`}</InternalLink>{` for more information.`}</li>
      <li parentName="ul">{`If possible, be forgiving of different input formats and small mistakes. It can greatly increase user experience of your product if you can fix common mistakes for the user.`}</li>
      <li parentName="ul">{`To decrease a cognitive load of the user, it is recommended to mark all required fields. Remember to provide explanation of the required field indicator at the top of your form.`}</li>
      <li parentName="ul">{`While not required, you can show the current and the expected amount of characters below the text area in the assistive text.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A text area is meant for situations where inputted text is multiline or contains more than one sentence.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TextArea id="textarea-default" label="Label" placeholder="Placeholder" helperText="Assistive text" required style={{
        maxWidth: '320px'
      }} mdxType="TextArea" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-a-tooltip",
      "style": {
        "position": "relative"
      }
    }}>{`With a tooltip`}<a parentName="h4" {...{
        "href": "#with-a-tooltip",
        "aria-label": "with a tooltip permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A text area can be provided with additional tooltip. Tooltip information should be considered as extra information, for example, why is this information gathered or how is it used. The user should have sufficient information on how to fill the input field in label and assistive text. You can find more information about Tooltips how they are used from the `}<InternalLink href="/components/tooltip" mdxType="InternalLink">{`Tooltip documentation page`}</InternalLink>{`.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TextArea id="textarea-tooltip" label="Label" placeholder="Placeholder" tooltipLabel="Tooltip label" tooltipButtonLabel="Tooltip button label" tooltipText="Tooltips contain nice to have information. Default Tooltip contents should not be longer than two to three sentences. For longer descriptions, provide a link to a separate page." style={{
        maxWidth: '320px'
      }} mdxType="TextArea" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      